<template>
      <div class="d-flex align-center justify-center px-10 py-10"  v-if="featuresLoading" style="height:100%; min-height:400px">
        <v-progress-circular
            color="primary"
            indeterminate
        ></v-progress-circular>
    </div>
    <div v-else>
      <v-row dense class="ma-0 pa-0" style="width: 100%;" :style="{ backgroundColor: editing ? '#E0F5F5': '#FFF' }">
        <!-- <v-col class="pt-5 px-8">
          <span style="font-weight: 500;">Space Details</span>
        </v-col>
        <v-spacer></v-spacer> -->
        <v-col>
            <div class="my-2 mx-3 align-right" v-if="!editing">
                <span class="alignleft">Click edit to update the details for this space.</span>
                <span><hb-link  @click="editDetails">Edit</hb-link></span>
            </div>
            <div class="py-1 mr-4 mt-0 align-right justify-end" v-if="editing">
                <hb-link class="mr-2" @click="editing = false">Cancel</hb-link>
                <hb-btn color="primary" @click="saveDetails" :disabled="isLoading($options.name)">Save</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </div>
        </v-col>

        <div class="slide-out-edit-btn">
        </div>

      </v-row>

      <v-divider></v-divider>

      <div :class="{editing: editing}" >
            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Space Number
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{unit.number}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value pa-0">
                            <v-text-field
                                name="space_number"
                                id="space_number"
                                label="space number"
                                v-validate="'required|max:45'"
                                v-model.lazy="edited.number"
                                data-vv-as="space number"
                                :error-messages="errors.collect('space_number')"
                                :hide-details="!errors.collect('space_number').length"
                                dense
                                flat
                                solo
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Space Type
                </v-col>
                <v-col cols="8" class="pl-6 pt-4">
                    {{ getUnitTypeTitle(unit) }}
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Available Date
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{unit.available_date | formatDate}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value pa-0">
                            <hb-date-picker
                                @click:clear="edited.available_date = ''"
                                label="MM/DD/YYYY"
                                clearable
                                dense
                                id="available_date"
                                data-vv-name="available_date"
                                name="available_date"
                                data-vv-as="available date"
                                v-model="edited.available_date">
                            </hb-date-picker>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Status
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{ unit.status == 1 ? 'Online' : 'Offline'}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value pa-0">
                            <v-select
                                :items="statusOptions"
                                item-text="label"
                                item-value="id"
                                :hide-details="!errors.collect('status').length"
                                v-validate="'required|max:45'"
                                v-model.lazy="edited.status"
                                label="Status"
                                id="status"
                                data-vv-as="status"
                                name="status"
                                :error-messages="errors.collect('status')"
                                dense
                                style="z-index:99999;"
                                flat
                                solo
                                single-line
                                class="input-font-size"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Access Area
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{ unit.area_name }}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value pa-0">
                            <v-select
                                v-model.lazy="edited.area_id"
                                :items="accessAreas"
                                item-text="name"
                                item-value="id"
                                :hide-details="!errors.collect('area').length"
                                label="Access Area"
                                id="area"
                                data-vv-as="area"
                                name="area"
                                :error-messages="errors.collect('area')"
                                dense
                                style="z-index:99999;"
                                flat
                                solo
                                single-line
                                class="input-font-size"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Product
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value" v-if="unit.Product">
                            {{unit.Product.name}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value pa-0">
                            <v-select
                                :items="products"
                                item-text="name"
                                item-value="id"
                                :hide-details="!errors.collect('product').length"
                                v-validate="'required|max:45'"
                                v-model.lazy="edited.product_id"
                                label="Product"
                                id="product"
                                data-vv-as="product"
                                name="product"
                                :error-messages="errors.collect('product')"
                                dense
                                style="z-index:99999;"
                                flat
                                solo
                                single-line
                                class="input-font-size"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Set Rate
                    <hb-tooltip class="alignright">
                        <template v-slot:body>
                        A rate used as a consistent, seldom-changing rate used to gauge the overall financial potential of your property.
                        </template>
                    </hb-tooltip>
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{unit.set_rate | formatMoney}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value">
                            <v-text-field
                                name="set_rate"
                                id="set_rate"
                                label="Rate"
                                prefix="$"
                                v-validate="'required|decimal:2|min_value:0|max_value:999999'"
                                v-model.lazy="edited.set_rate"
                                data-vv-as="unit number"
                                :error-messages="errors.collect('set_rate')"
                                :hide-details="!errors.collect('set_rate').length"
                                dense
                                flat
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row v-show="editing">
                        <v-col cols="12" sm="12" class="hb-table-value">
                            <rent-rules v-if="unit.id" :price="unit.set_rate" :unit_id="unit.id" style="overflow-y: auto;"></rent-rules>
                        </v-col>
                    </v-row>     -->
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Sell Rate
                    <!-- <hb-tooltip class="alignright">
                        <template v-slot:body>
                        A rate used as a consistent, seldom-changing rate used to gauge the overall financial potential of your property.
                        </template>
                    </hb-tooltip> -->
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{unit.price | formatMoney}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value">
                            <v-text-field
                                name="price"
                                id="price"
                                label="Price"
                                prefix="$"
                                v-validate="'required|decimal:2|min_value:0|max_value:999999'"
                                v-model.lazy="edited.price"
                                data-vv-as="unit number"
                                :error-messages="errors.collect('price')"
                                :hide-details="!errors.collect('price').length"
                                dense
                                flat
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="12" class="hb-table-value">
                            <rent-rules v-if="unit.id" :price="unit.price" :unit_id="unit.id" style="overflow-y: auto;"></rent-rules>
                        </v-col>
                    </v-row>    
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Description
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="12" class="hb-table-value">
                            <span v-html="$options.filters.nl2br(unit.description)"></span>
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="12" class="hb-table-value pa-0">
                            <v-textarea
                                id="description"
                                name="description"
                                label="Enter Description"
                                data-vv-as="description"
                                row="1"
                                v-model="edited.description"
                                v-validate="'max:1000'"
                                :error-messages="errors.collect('description')"
                                :hide-details="!errors.collect('description').length"
                                single-line
                                auto-grow
                                flat
                                solo
                                class="input-font-size"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                   Website Category
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            <span v-if="unit.category_id">{{unit.Category.name | capitalize}}</span>
                            <span v-else> - </span>
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col v-show="!categories.length" cols="12" sm="12" class="hb-table-value pa-0">
                            <span>
                                <h4 class="notification">No Categories found</h4>
                                <p class="subdued">
                                    Categories can help you group your spaces into logical sections to make them easier to work with. Set them up in Settings > Categories.
                                </p>
                            </span>
                        </v-col>
                        <v-col v-show="categories.length" cols="12" sm="6" class="hb-table-value pa-0">
                            <v-select
                                hide-details
                                :items="categories"
                                item-text="name"
                                item-value="id"
                                v-model.lazy="category"
                                label="Category"
                                id="category"
                                data-vv-as="category"
                                name="category"
                                style="z-index:99999;"
                                dense
                                flat
                                solo
                                single-line
                                return-object
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>


            <!-- // Space Size -->
            <span>

                <div class="interaction row ma-0" >
                    <div class="hb-table-label col-4 interaction-details">
                        <div class="slide-out-section-label">
                            Size</div>
                    </div>
                    <div class="col-8 interaction-text">
                        <span class="pl-3" v-show="!editing">{{unit.width}}W x {{unit.length}}L x {{unit.height}}H</span>
                        <v-row class="ma-0" v-show="editing">
                            <v-col md="4">
                                <v-text-field
                                    type="text"
                                    v-model="edited.width"
                                    v-validate="'required|decimal:2|min_value:0|max_value:999'"
                                    name="width"
                                    id="width"
                                    label="Width"
                                    hide-details
                                    suffix="ft."
                                    data-vv-as="Width"
                                    :error-messages="errors.collect('width')"
                                    @focus="$event.target.select()"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col md="4">
                                <v-text-field
                                    type="text"
                                    v-model="edited.length"
                                    v-validate="'required|decimal:2|min_value:0|max_value:999'"
                                    name="length"
                                    id="length"
                                    label="Length"
                                    hide-details
                                    @focus="$event.target.select()"
                                    suffix="ft."
                                    data-vv-as="Length"
                                    :error-messages="errors.collect('length')"
                                >
                                </v-text-field>

                            </v-col>
                            <v-col md="4">
                                <v-text-field
                                    type="text"
                                    v-model="edited.height"
                                    v-validate="'required|decimal:2|min_value:0|max_value:99'"
                                    name="height"
                                    id="height"
                                    label="Height"
                                    hide-details
                                    suffix="ft."
                                    data-vv-as="Height"
                                    :error-messages="errors.collect('height')"
                                    @focus="$event.target.select()"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col class="pt-0 pb-0">
                                <span v-show="errors.has('height')" class="status-block error-block field-error">{{ errors.first('height') }}</span>
                                <span v-show="errors.has('width')" class="status-block error-block field-error">{{ errors.first('width') }}</span>
                                <span v-show="errors.has('length')" class="status-block error-block field-error">{{ errors.first('length') }}</span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </span>
            <!-- Space Size // -->

            <!-- BCT: Added for HB-1335  -->
            <!-- // Door Size -->
            <span>

                <div class="interaction row ma-0" >
                    <div class="hb-table-label col-4 interaction-details">
                        <div class="slide-out-section-label">
                            Door Size</div>
                    </div>
                    <div class="col-8 interaction-text">
                        <span class="pl-3" v-show="!editing">{{unit['door width']}}W  x {{unit['door height']}}H</span>
                        <v-row class="ma-0" v-show="editing">
                            <v-col md="6">
                                <v-text-field
                                    type="text"
                                    v-model="edited['door width']"
                                    name="door_width"
                                    v-validate="'decimal:2|min_value:0|max_value:999'"
                                    id="door_width"
                                    label="Door Width"
                                    hide-details
                                    @focus="$event.target.select()"
                                    suffix="ft."
                                    :error-messages="errors.collect('door_width')"
                                    data-vv-as="Door Width"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col md="6">
                                <v-text-field
                                    type="text"
                                    v-model="edited['door height']"
                                    name="door_height"
                                    v-validate="'decimal:2|min_value:0|max_value:999'"
                                    id="door_height"
                                    label="Door Height"
                                    hide-details
                                    suffix="ft."
                                    :error-messages="errors.collect('door_height')"
                                    data-vv-as="Door Height"
                                    @focus="$event.target.select()"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <span v-show="errors.has('door_width')" class="status-block error-block field-error">{{ errors.first('door_width') }}</span>
                                <span v-show="errors.has('door_height')" class="status-block error-block field-error">{{ errors.first('door_height') }}</span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </span>
            <!-- Door Size // -->

            <v-row class="hb-table-row pa-0 ma-0">
                <v-col class="hb-table-label py-4 pl-6" cols="4">
                    Space Floor
                </v-col>
                <v-col cols="8" class="pl-6 pt-1 pb-1">
                    <v-row v-show="!editing">
                        <v-col cols="12" sm="4" class="hb-table-value">
                            {{unit.floor}}
                        </v-col>
                    </v-row>
                    <v-row v-show="editing">
                        <v-col cols="12" sm="6" class="hb-table-value pa-0">
                            <HbTextField
                                name="space_floor"
                                id="space_floor"
                                label="space floor"
                                v-model.lazy="edited.floor"
                                v-validate="'required|integer|min_value:-999|max_value:999'"
                                data-vv-as="space floor"
                                :error-messages="errors.collect('space_floor')"
                                placeholder="space floor"
                                dense
                                flat
                                solo
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>


      <v-row dense class="ma-0" style="width: 100%;" :style="{ backgroundColor: editing ? '#E0F5F5': '#FFF' }">

        <v-spacer></v-spacer>
        <v-col class="align-right py-4" >
            <div class="mt-n1 mr-4" v-if="!editing">
                <hb-link @click="editDetails">Edit</hb-link>
            </div>
            <div :class="{'mt-n2 mb-n1' : editing}" class="mr-4 d-flex align-center justify-end" v-if="editing">
                <hb-link class="mr-2" @click="editing = false">Cancel</hb-link>
                <hb-btn color="primary" @click="saveDetails" :disabled="isLoading($options.name)">Save</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </div>
        </v-col>

        <div class="slide-out-edit-btn">
        </div>

      </v-row>
    </div>

</template>

<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import Modal from '../assets/Modal.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import RentRules from '../includes/RentRules.vue';
    import { unitOptionsMixin } from '../../mixins/unitOptionMixin.js'
    import { EventBus } from '../../EventBus.js';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import { mapActions,mapGetters } from 'vuex';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';

    export default{
        name: "UnitDetails",
        data(){
            return {
                unit: {},
                editing: false,
                featuresLoading: false,

                edited: {
                    number: '',
                    description:'',
                    status: '',
                    featured: '',
                    floor: '',
                    price: '',
                    beds: '',
                    baths: '',
                    sqft: '',
                    pets: '',
                    parking: '',
                    furnished: '',
                    laundry: '',
                    category_id: '',
                    set_rate: '',
                    'year built': '',
                    'vehicle storage':'',
                    'unit type':'',
                    'door height': '',
                    'door width': '',
                },
                products: [],
                categories:[],
                category:{},
                statusOptions: [
                    {id: 1, label: 'Online'},
                    {id: 2, label: 'Offline'}
                ],
                accessAreas: [],
                units: []

            }
        },
        props: ['unit_id', 'bulk_units', 'unit_number', 'reportType'],
        mixins:[unitOptionsMixin, notificationMixin], 
        components:{
            Dropdown,
            Modal,
            Datepicker,
            Loader,
            Status,
            RentRules,
            HbDatePicker
        },
        async created(){
            await this.fetchData();
            this.fetchCategories();
            this.fetchProducts();
            this.fetchAccessAreas();
            //EventBus.$on('undo_111', this.fetchData);
        },
        /*
        destroyed(){
            EventBus.$off('undo_111', this.fetchData);
        },
        */
        filters: {

        },
        methods:{
            ...mapActions({
                getProductsByType: 'propertiesStore/getProductsByType'
            }),
            editDetails(){
                this.edited = Object.assign(this.edited, this.unit);
                this.category = this.unit.Category;

                this.editing = true;

            },
            async fetchProducts(){
                var params = `${this.unit.property_id}/products?type=rent&category_type=rent`;
                this.products = await this.getProductsByType(params);
            },
            fetchCategories(){
                api.get(this, api.CATEGORIES, {unit_type_id: this.unit.unit_type_id}).then(r => {
                    this.categories = r.categories;
                });
            },
            fetchAccessAreas(){
                api.get(this, api.PROPERTIES + this.unit.property_id + "/areas?active=1").then(r => {
                    this.accessAreas = r;
                }).catch(err => {
                    this.showMessageNotification({ type: 'error', description: "No Access Areas have been set in Hummingbird. Please configure Access Areas or contact the Customer Support team." });
                });
            },
            resetEdited(){
                this.edited = {
                    number:'',
                    description:'',
                    featured:'',
                    status: '',
                    area_id: '',
                    price: '',
                    beds: '',
                    baths: '',
                    sqft: '',
                    pets: '',
                    parking: '',
                    furnished: '',
                    laundry: '',
                    category_id: '',
                    set_rate: '',
                    'year built': '',
                    'vehicle storage':'',
                    'unit type':'',
                    'door height': '',
                    'door width': '',
                }
            },

            saveDetails(){

                var data = this.assembleInfo(this.edited);
                data.category_id = this.category.id;
                data.scope = 'space_info';
                this.validate(this).then(status => {
                    if(!status) return;

                    api.put(this, api.PROPERTIES + this.unit.property_id + '/units/' + this.unit.id, data  ).then(async () => {

                        let change_area_error;
                        if (this.edited.area_id && this.edited.area_id != this.unit.area_id) {
                            await api.put(this, api.UNITS + this.unit.id + '/area', { area_id: this.edited.area_id }).catch((error) => {
                                change_area_error = error;
                            });
                        }

                        let report = this.$store.getters['reportStore/getAllStructures'][this.reportType];  
                        if(report) {
                            let report_id =  report.current_view !== 'template' ? (report.current_view || null) : null;
                            let viewBackup = JSON.parse( JSON.stringify(report.views.filter(x=> x.id === report_id)) );
                            let view = report.views.filter(x=> x.id === report_id);
                            if(view && view.length) {
                                viewBackup = viewBackup[0];
                                view = view[0];
                                view.filters.search = {
                                    unit_id:this.unit.id
                                }
                                view.filters.limit = 1;
                                view.filters.offset = 0;
                            }

                            let updatedData = await api.post(this, api.REPORTS + `types/${this.reportType}`, JSON.parse(JSON.stringify(view.filters)));
                            EventBus.$emit('unit_edited_single', updatedData.table_data[0]);

                            this.$store.commit('reportStore/setSearchParams', {
                                report_template: viewBackup.template,
                                report_id: report_id,
                                search:  viewBackup.filters.search,
                                offset: viewBackup.filters.offset,
                                limit: viewBackup.filters.limit,
                                page: viewBackup.filters.page
                            });
                        }
                        // BCT - Hb-72 Space Profile
                        if (change_area_error) {
                            this.showMessageNotification({type: 'error', description: change_area_error});
                        } else {
                            this.showMessageNotification({type: 'success', description: `Space info has been updated for Space ${this.unit_number}`});
                        }
                        this.fetchData();
                        this.resetEdited();
                        this.editing = false;
                    }).catch(err => {
                        this.showMessageNotification({type: 'error', description: err});
                    });

                });
            },

            assembleInfo(unit){
                const unit_type_meta = this.getUnitTypeMeta(this.unit);
                var data = {
                    number: unit.number,
                    unit_type_id:unit.unit_type_id,
                    featured: unit.featured? 1:0,
                    floor: unit.floor,
                    status: unit.status == 2 ? 0: 1,
                    available_date: unit.available_date ?  moment(unit.available_date).startOf('day').format('YYYY-MM-DD'): null,
                    product_id: unit.product_id,
                    price: unit.price,
                    set_rate: unit.set_rate,
                    description: unit.description,
                    category_id: unit.category_id,
                    width: unit.width,
                    height: unit.height,
                    length: unit.length,
                    'door height': unit['door height'],
                    'door width': unit['door width'],
                }

                if(unit_type_meta.unit_type_label == 'storage'){
                    data.width = unit.width;
                    data.height = unit.height;
                    data.length = unit.length;
                    data['vehicle storage'] = unit['vehicle storage'];
                    data['unit type'] = unit['unit type'];

                }

                return data;
            },

            fetchData(){
                this.featuresLoading = true;
                return api.get(this, api.UNITS + this.unit_id + '/features' ).then(r=> {
                    this.unit = r.unit;
                    this.unit.status = this.unit.status ? 1: 2;
                    this.unit.available_date = r.unit.available_date ?  moment(r.unit.available_date, 'YYYY-MM-DD').toDate(): null;

                    if(typeof this.unit.furnished != 'undefined'){
                        this.unit.furnished = +this.unit.furnished;
                    }

                }).finally(() => this.featuresLoading = false)
            },
        },
        watch:{
            unit_id(){
                this.resetEdited();
                this.editing = false;
                this.fetchData();
            },

            "errors.items"(val) {
                let sizeField = [];
                if(val && val.length) {
                    const errorMsg = [];
                    val.forEach((item) => {
                        if (item.field === "space_number" && item.rule === "required" ) {
                            errorMsg.push("Please enter a valid space number.")
                        }
                        if (item.field === "price" && ["required", "decimal"].includes(item.rule) ) {
                            errorMsg.push("Please enter a valid price amount.")
                        }
                        if (item.field === "set_rate" && ["required", "decimal"].includes(item.rule) ) {
                            errorMsg.push("Please enter a valid Set Rate amount.")
                        }

                        if (item.field === "width" && ["required", "decimal"].includes(item.rule)) {
                            sizeField.push('width');
                        }
                        
                        if (item.field === "length" && ["required", "decimal"].includes(item.rule) ) {
                            sizeField.push('length');
                        }
                        
                        if (item.field === "height" && ["required", "decimal"].includes(item.rule) ) {
                            sizeField.push('height');
                        }

                        if (item.field === "door_height" && [ "decimal"].includes(item.rule) ) {
                            sizeField.push('door_height');
                        }

                        if (item.field === "door_width" && [ "decimal"].includes(item.rule) ) {
                            sizeField.push('door_width');
                        }

                        if (item.field === "space_floor" && (["required", "integer", "max_value", "min_value"].includes(item.rule)) ) {
                            errorMsg.push("Space floor must be an integer number in range -999 to 999")
                        }

                    })

                    if (sizeField.length) {
                        errorMsg.push(`Please enter a valid size value for ${sizeField.join(', ')}.`);
                    }

                    let desc = 'There are errors in your form, correct them before continuing.';
                    this.showMessageNotification({type: 'error', description: desc, list: errorMsg});

                }
            }
        }
    }
</script>

<style scoped>
    .interaction-element span.icon{
        display:none;
        cursor: pointer;
        font-size: 12px;
        line-height: 12px;
        padding: 0;
        color: #ccc;
    }
    .interaction-element span.icon.delete:hover{
        color: #D91E18;
    }

    .interaction-element:hover span.icon{
        display:inline;
    }

    .slide-out-section.editing .interaction-text{
        padding-top: 10px;
        padding-bottom: 0px;
    }
    .interaction-details {
        padding: 12px 24px;
        background-color: #F4F6F8;
    }
    .interaction-text {
        padding: 12px;
    }

    .interaction {
        border-bottom: 1px solid #dce8ef;
    }
    .alignleft {
        float: left;
    }
    .alignright {
        float: right;
    }


</style>
